import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi djupdyker i Purism och Librem 5. Firefox får prestandaförbättringar. Rust tar mark. Torvalds suktar efter Apples nya hårdvara. Det, och mycket mer, häng med!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Librem 5 "Massproduktion": `}<a parentName="li" {...{
          "href": "https://puri.sm/posts/librem-5-mass-production-phone-has-begun-shipping/"
        }}>{`https://puri.sm/posts/librem-5-mass-production-phone-has-begun-shipping/`}</a></li>
      <li parentName="ul">{`Firefox 83: `}<a parentName="li" {...{
          "href": "https://www.mozilla.org/en-US/firefox/83.0/releasenotes/"
        }}>{`https://www.mozilla.org/en-US/firefox/83.0/releasenotes/`}</a><ul parentName="li">
          <li parentName="ul">{`https-only: `}<a parentName="li" {...{
              "href": "https://blog.mozilla.org/security/2020/11/17/firefox-83-introduces-https-only-mode/"
            }}>{`https://blog.mozilla.org/security/2020/11/17/firefox-83-introduces-https-only-mode/`}</a></li>
          <li parentName="ul">{`The Linux Experiment browser test på Linux: `}<a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=DPVrjH1mkkk"
            }}>{`https://www.youtube.com/watch?v=DPVrjH1mkkk`}</a></li>
        </ul></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`Rust tar mark: `}<a parentName="li" {...{
          "href": "https://serokell.io/blog/rust-companies"
        }}>{`https://serokell.io/blog/rust-companies`}</a>{` `}<a parentName="li" {...{
          "href": "https://insights.stackoverflow.com/survey/2020#most-loved-dreaded-and-wanted"
        }}>{`https://insights.stackoverflow.com/survey/2020#most-loved-dreaded-and-wanted`}</a></li>
      <li parentName="ul">{`Hacktoberfest-statistik: `}<a parentName="li" {...{
          "href": "https://hacktoberfest.cube.dev/"
        }}>{`https://hacktoberfest.cube.dev/`}</a></li>
      <li parentName="ul">{`Grattis Gimp 25 år (tack bittin): `}<a parentName="li" {...{
          "href": "https://www.gimp.org/news/2020/11/21/25-years-of-gimp/"
        }}>{`https://www.gimp.org/news/2020/11/21/25-years-of-gimp/`}</a></li>
      <li parentName="ul">{`Torvalds och nya Apple-laptops: `}<a parentName="li" {...{
          "href": "https://old.reddit.com/r/linux/comments/jz16o9/thoughts_of_linus_torvalds_on_m1_macs/"
        }}>{`https://old.reddit.com/r/linux/comments/jz16o9/thoughts_of_linus_torvalds_on_m1_macs/`}</a><ul parentName="li">
          <li parentName="ul">{`Linux på M1: `}<a parentName="li" {...{
              "href": "https://twitter.com/never_released/status/1327398102983176192"
            }}>{`https://twitter.com/never_released/status/1327398102983176192`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`hw-probe: `}<a parentName="li" {...{
          "href": "https://github.com/linuxhw/Trends"
        }}>{`https://github.com/linuxhw/Trends`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linuxhardware/comments/jwclmq/linux_statistics_for_october_2020/"
        }}>{`https://www.reddit.com/r/linuxhardware/comments/jwclmq/linux_statistics_for_october_2020/`}</a><ul parentName="li">
          <li parentName="ul">{`Alex 8 år gamla kontorsdator: `}<a parentName="li" {...{
              "href": "https://linux-hardware.org/?probe=8047b68d9e"
            }}>{`https://linux-hardware.org/?probe=8047b68d9e`}</a></li>
        </ul></li>
      <li parentName="ul">{`Stacer: `}<a parentName="li" {...{
          "href": "https://github.com/oguzhaninan/Stacer"
        }}>{`https://github.com/oguzhaninan/Stacer`}</a>{`      `}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">{`Alex städar i Rustäventyret`}</li>
      <li parentName="ul">{`Seb går bort från gmail`}<ul parentName="li">
          <li parentName="ul">{`Gmail -> ProtonMail: `}<a parentName="li" {...{
              "href": "https://protonmail.com/support/knowledge-base/transitioning-from-gmail-to-protonmail/"
            }}>{`https://protonmail.com/support/knowledge-base/transitioning-from-gmail-to-protonmail/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Mail!`}</li>
      <li parentName="ul">{`RÄTTNING! (Från S05E03) RaspberryPi har 4 kärnor, inte 8.`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      